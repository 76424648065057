import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Leadership from "./pages/Leadership";
import Membership from "./pages/Membership";
import Documentation from "./pages/Documentation";
import Notices from './pages/Notices';
import Learn from "./pages/Learn";
import ContactUs from "./pages/ContactUs";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ErrorBoundary from "./ErrorBoundary";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

const Layout = () => {
  return (
    <div className="flex flex-col min-h-full w-full bg-blue-50 items-center">
      <div className="sticky top-0 z-10 w-full">
        <Navbar />
      </div>
      <div className="flex flex-col h-full overflow-y-auto scrollbar-hide items-center w-full">
        <div className="flex h-fit w-full justify-start">
          <Outlet />
        </div>
        <div className="justify-end flex flex-col w-full h-fit bg-[#003e59] items-center text-white">
          <Footer />
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/leadership",
        element: <Leadership />,
      },
      {
        path: "/membership",
        element: <Membership />,
      },
      {
        path: "/documentation",
        element: <Documentation />,
      },
      {
        path: "/notices",
        element: <Notices />,
      },
      {
        path: "/e-learning",
        element: <Learn />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/terms-and-condition",
        element: <TermsAndConditions />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
    ]
  }
]);

const App = () => {
  return (
    <div>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
};

export default App;